import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const Member = props => {
    return (
        <div>
            <div className='w-32 h-32 overflow-hidden rounded-full border-4 border-gray-300 m-auto'>
                <Img
                    fixed={props.data.childImageSharp.fixed}
                    alt={props.name}
                    objectFit='cover'
                    objectPosition='50% 50%'
                    imgStyle={{ width: '128px', height: '128px' }}
                />
            </div>
            <h4 className='mt-1 font-medium'>{props.name}</h4>
        </div>
    )
}

export default props => {
    return (
        <>
            <div className='max-w-3xl mx-auto bg-white shadow-xl border-t-4 border-gray-900 rounded mt-8'>
                <div className='px-8 py-12 text-center'>
                    <h1 className='font-medium uppercase tracking-wide text-sm text-gray-700'>
                        About Mono
                    </h1>
                    <p className='text-sm mt-3'>
                        Mono FM is a podcast hosting company based in Tokyo, Japan.
                    </p>
                    <div className='mt-12'>
                        <h2 className='uppercase tracking-wide text-sm font-medium text-gray-700'>
                            Team
                        </h2>
                        <ul className='mt-8 sm:flex justify-center flex-wrap'>
                            <li className='sm:w-1/2'>
                                <Member name='Kashif Siddiqui' data={props.data.kashif} />
                            </li>
                            <li className='mt-3 sm:mt-0 sm:w-1/2'>
                                <Member name='Cuong Quoc' data={props.data.khan} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='px-8 py-12 text-center'>
                    <h1 className='font-medium uppercase tracking-wide text-sm text-gray-700'>
                        Credits
                    </h1>
                    <div className='text-sm'>
                        <ul className='mt-2 mx-auto'>
                            <li className=''>
                                Podcast artwork by{' '}
                                <a
                                    target='_blank'
                                    rel='nofollow noopener noreferrer'
                                    className='border-gray-300 border-b hover:border-gray-400'
                                    href='https://unsplash.com/@mattbotsford'
                                >
                                    Matt Botsford on Unsplash
                                </a>
                            </li>
                            <li className=''>
                                Icons from{' '}
                                <a
                                    target='_blank'
                                    rel='nofollow noopener noreferrer'
                                    className='border-gray-300 border-b hover:border-gray-400'
                                    href='https://heroicons.dev'
                                >
                                    Heroicons (Steve Schoger and Adam Wathan)
                                </a>
                            </li>
                            <li className=''>
                                Icons made by{'   '}
                                <a
                                    target='_blank'
                                    rel='nofollow noopener noreferrer'
                                    className='border-gray-300 border-b hover:border-gray-400'
                                    href='https://www.flaticon.com/authors/google'
                                >
                                    Google from Flaticon
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export const query = graphql`
    query TeamImages {
        kashif: file(relativePath: { eq: "team/kashif.jpg" }) {
            childImageSharp {
                fixed(width: 256, height: 256) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        khan: file(relativePath: { eq: "team/khan.jpg" }) {
            childImageSharp {
                fixed(width: 256, height: 256) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`
